import '@babel/polyfill';
import 'whatwg-fetch';

import smoothscroll from 'smoothscroll-polyfill';
import { syncHistoryWithStore } from 'react-router-redux';

import React from 'react';
import ReactDOM from 'react-dom';

import 'normalize.css';
import './style/global.css';

import { useDispatch } from 'react-redux';
import wireUpInternals from './internals';

import { store } from 'Internals/store';
import zendesk from 'Internals/zendesk';
import { browserHistory } from 'Internals/browserHistory';
import App from './App';
import { sendHibernationAnalyticsTrigger } from './utils/Analytics';
import { AppDispatch } from 'Types/state.types';

window.sesamBasename = process.env.PUBLIC_PATH;

const baseUrlElement = document.getElementById('baseUrl') as HTMLBaseElement;

if (baseUrlElement) {
  baseUrlElement.href = process.env.PUBLIC_PATH ?? '/';
}

export const useAppDispatch = () => useDispatch<AppDispatch>();

// Wire up all non-React components
// Kind of a hacky way to use Redux, but it works
wireUpInternals(store);

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store);

// Wire up Google Analytics and Zendesk to the browser history
history.listen((location) => {
  const pathname = location.pathname;
  zendesk.updatePath(pathname);

  // Sending an analytics signal to the portal on a subscription page view.
  const portalUrl = store.getState().globals.portalUrl;
  sendHibernationAnalyticsTrigger(portalUrl);
});

// Whatever Zendesk and Smoothscroll packages need
zendesk.polyfill();
smoothscroll.polyfill();

ReactDOM.render(<App store={store} history={history} />, document.getElementById('app'));
