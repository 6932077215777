import { Provider } from 'react-redux';
import { Router, Route, IndexRedirect, IndexRoute, Redirect } from 'react-router';
import React from 'react';

import 'normalize.css';
import './style/global.css';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
  requireAuth,
  redirectFromIndexRoute,
  setLastViewedTab,
  requireGroupMembership,
} from './internals';
import memberGuard from './subscription/components/member-guard';
import connectedGuard from './subscription/components/connected-guard';
import renderIfDatabrowserConnected from './subscription/components/databrowser-connected-guard';

import Auth from './pages/auth';
import { SubscriptionPage } from './pages/Subscription.page';
import User from './pages/user';
import NotFound from './pages/notfound';
import ServerError from './pages/server-error';

import { AppRoot } from './components/app-root/AppRoot';
import { DashboardPage } from './pages/dashboard/Dashboard.page';
import AppAlerts from './pages/alerts';
import AppCreateSubscription from './pages/create-subscription';
import AppCreateTrial from './pages/create-trial';
import AppRequestTrial from './pages/request-trial';

import NewSignupPage from './auth/pages/signup-new';

import AuthLogin from './auth/login';
import AuthLogout from './auth/logout';
import AuthSignup from './auth/signup';
import AuthForgotPassword from './auth/forgot-password';
import AuthResetPassword from './auth/reset-password';
import AuthVerifyEmail from './auth/verify-email';
import AuthApproveTos from './pages/approve_tos';

import FlowPage from './pages/flow/FlowPage';
import FlowDashboardPage from './pages/flow/flow-dashboard/FlowDashboardPage';
import FlowConfigPage from './pages/flow/flow-config/FlowConfigPage';
import FlowInputPage from './pages/flow/flow-input/FlowInputPage';
import FlowOutputPage from './pages/flow/flow-output/FlowOutputPage';

import SubOverview from './subscription/pages/overview/Overview';
import SubPipe from './subscription/pages/pipes/Pipe';
import SubPipeList from './components/pipes-list/pipes-list';
import SubPipeListPermissions from './subscription/pages/pipes/PipesPermissions';
import SubPipeDashboard from './components/pipe-dashboard/PipeDashboard';
import SubEditPipe from './subscription/pages/pipes/EditPipe';
import SubPipeInput from './components/pipe-input/PipeInput';
import SubPipeOutput from './components/pipe-output/PipeOutput';
import PipeInferredSchema from './components/pipe-inferred-schema/PipeInferredSchema';
import { PipeDeadLetters } from './components/pipe-dead-letters/PipeDeadLetters';
import SubNewPipe from './subscription/pages/pipes/NewPipe';
import PipeGraph from './subscription/pages/pipes/PipeGraph';
import SubPipeExecutionLog from './subscription/pages/pipes/PipeExecutionLog';
import SubPipePermissions from './subscription/pages/pipes/PipePermissions';
import SubPipePrototypePermissions from './subscription/pages/pipes/PipesPrototypePermissions';
import SubPipeInsights from './subscription/pages/pipes/insights/Insights';
import SubPipeGlobalOverview from './subscription/pages/pipes/PipeOverview';
import SubPipeNotifications from './components/pipe-notifications/PipeNotifications';
import SubPipeDebug from './subscription/pages/pipes/PipeDebug';
import SubPipeIndexes from './components/pipe-indexes/PipeIndexes';
import SubDataset from './subscription/pages/datasets/Dataset';
import { DatasetInspectorContainer } from './components/dataset-inspector/DatasetInspectorContainer';
import SubDatasetPermissions from './subscription/pages/datasets/DatasetPermissions';
import { DatasetIndexes as SubDatasetIndexes } from './subscription/pages/datasets/DatasetIndexes';
import SubSystem from './subscription/pages/systems/System';
import SubSystemList from './components/systems-list/systems-list';
import SubSystemListPermissions from './subscription/pages/systems/SystemsPermissions';
import SubSystemPrototypePermissions from './subscription/pages/systems/SystemsPrototypePermissions';
import SubNewSystem from './subscription/pages/systems/NewSystem';
import EditSystem from './subscription/pages/systems/EditSystem';
import SystemStatusPage from './subscription/pages/systems/SystemStatus';
import SystemOverview from './subscription/pages/systems/SystemOverview';
import SystemSecretsPage from './subscription/pages/systems/SystemSecrets';
import SubSystemPermissions from './subscription/pages/systems/SystemPermissions';
import SystemGraph from './subscription/pages/systems/SystemGraph';
import SubSystemDashboard from './components/system-dashboard/SystemDashboard';

import SearchPage from './components/search/SearchPage';
import ModelsListPage from './components/models-list/ModelsListPage';
import EntityTypesListPage from './components/entity-types-list/EntityTypesListPage';
import EntityTypeOverview from './components/entity-type-overview/EntityTypeOverview';
import EntityTypePage from './components/entity-type-page/EntityTypePage';
import EntityTypePropertyPage from './components/entity-type-property-page';
import EntityTypePropertyOverview from './components/entity-type-property-overview';
import ModelPage from './components/model-page/ModelPage';
import ModelOverview from './components/model-overview/ModelOverview';

import FlowsList from './components/flows-list/FlowsList';

import Gdpr from './subscription/pages/gdpr';
import GdprDataTypes from './subscription/pages/gdpr-data-types';
import GdprAccessRequests from './subscription/pages/gdpr-access-request';
import GdprChangeRequests from './subscription/pages/gdpr-change-request';
import GdprTranslations from './subscription/pages/gdpr-translations';
import GdprPurposePage from './pages/gdpr/gdpr-purpose/GdprPurposePage';
import GdprDatatypePage from './pages/gdpr/gdpr-data-type/GdprDataTypePage';
import GdprPolicyPage from './pages/gdpr/gdpr-policies/GdprPolicyPage';

import withGdprRedirect from './components/gdpr-redirect-hoc/gdpr-redirect-hoc';

import DatabrowserSettings from './subscription/pages/settings-databrowser';

import { SubscriptionSettings } from './subscription/pages/settings-subscription/SubscriptionSettings';

import DhSettingsQueues from './subscription/pages/settings-datahub-queues';
import DhSettingsInternalDatasets from './subscription/pages/settings-datahub-internal-datasets';
import DhSettingsLogs from './components/settings-datahub-logs/SettingsDatahubLogs';
import DhSettingsVariables from './subscription/pages/settings-datahub-variables';
import DhSettingsMetadata from './subscription/pages/settings-datahub-metadata';
import DhSettingsTools from './subscription/pages/settings-datahub-tools';
import DhSettingsPermissions from './subscription/pages/settings-datahub-permissions';
import DatahubStacktracePage from './pages/datahub/stacktrace/StacktracePage';

import DatabrowserSettingsConfigFile from './subscription/pages/settings-databrowser-configfile';
import DatabrowserSettingsDatabrowser from './subscription/pages/settings-databrowser-databrowser';
import DatabrowserSettingsNetwork from './subscription/pages/settings-databrowser-network';

import SubSettingsBasics from './subscription/pages/settings-subscription-basics';
import SubSettingsMembers from './subscription/pages/settings-subscription-members';
import SubSettingsProducts from './subscription/pages/settings-subscription-products';
import SubSettingsVpn from './subscription/pages/settings-subscription-vpn';
import { SettingsSubscriptionNetwork } from './subscription/pages/settings-subscription-network/SettingsSubscriptionNetwork';
import SubSettingsRoles from './subscription/pages/settings-subscription-roles';
import SubSettingsLicense from './subscription/pages/settings-subscription-license';
import SubSettingsJwt from './subscription/pages/settings-subscription-jwt';
import SubSettingsNotifications from './subscription/pages/settings-subscription-notifications';

import UserProfile from './user/pages/profile';
import UserChangePassword from './user/pages/change-password';
import UserPaymentMethods from './user/pages/payment-methods';
import { CreatePaymentMethod } from './user/pages/payment-methods/create';
import { EditPaymentMethod } from './user/pages/payment-methods/edit';
import NIInspectorPage from './components/ni-inspector-page/NIInspectorPage';
import PricesPage from './pages/PricesPage';
import { sendHibernationAnalyticsTrigger } from './utils/Analytics';

const renderIfSubMember = memberGuard; // Just an alias
const renderIfSubConnected = (c: React.ReactNode) => memberGuard(connectedGuard(c));

const App = ({ store, history }) => {
  // Sending an analytics signal to the portal on a subscription page when the user refreshes the browser.
  const portalUrl = store.getState().globals.portalUrl;
  sendHibernationAnalyticsTrigger(portalUrl);

  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router history={history}>
          <Route component={AppRoot}>
            <Redirect from="/" to="dashboard" />
            <Route
              path="dashboard"
              component={withGdprRedirect(DashboardPage, 'dashboard')}
              onEnter={requireAuth}
            />
            <Route path="alerts" component={AppAlerts} onEnter={requireAuth} />
            <Route
              path="create-subscription"
              component={AppCreateSubscription}
              onEnter={requireAuth}
            />
            <Route path="create-trial" component={AppCreateTrial} onEnter={requireAuth} />
            <Route path="request-trial" component={AppRequestTrial} onEnter={requireAuth} />
            <Route path="auth" component={Auth}>
              <Route path="gdpr-signup" component={NewSignupPage} />
              <Route path="login" component={AuthLogin} />
              <Route path="signup" component={AuthSignup} />
              <Route path="forgot-password" component={AuthForgotPassword} />
              <Route path="reset-password" component={AuthResetPassword} />
              <Route path="logout" component={AuthLogout} />
              <Route path="verify-emailaddress" component={AuthVerifyEmail} />
            </Route>

            <Route path="approve-tos" component={AuthApproveTos} onEnter={requireAuth} />

            <Route path="user" component={User} onEnter={requireAuth}>
              <IndexRedirect to="profile" />
              <Route path="profile">
                <IndexRoute component={UserProfile} />
                <Route path="change-password" component={UserChangePassword} />
              </Route>
              <Route path="payment-methods">
                <IndexRoute component={UserPaymentMethods} />
                <Route path="create" component={CreatePaymentMethod} />
                <Route path="edit/:pmID" component={EditPaymentMethod} />
              </Route>
            </Route>

            <Route path="prices" component={PricesPage} onEnter={requireAuth} />

            <Route
              path="subscription/:subId"
              component={withGdprRedirect(SubscriptionPage, 'subscription')}
              onEnter={requireAuth}
            >
              <IndexRedirect to="overview" />
              <Route path="overview" component={renderIfSubConnected(SubOverview)} />
              {/* the appliance redirect to this url, so don't remove it */}
              <Redirect from="connect" to="settings/network" />
              <Route path="pipes">
                <IndexRoute
                  onEnter={(route, replace, callback) =>
                    redirectFromIndexRoute(
                      'pipes',
                      'all',
                      `/subscription/${route.params.subId}/pipes`,
                      replace,
                      callback
                    )
                  }
                />
                <Route
                  path="all"
                  component={renderIfSubConnected(SubPipeList)}
                  onEnter={(route, replace, callback) => setLastViewedTab('pipes', 'all', callback)}
                />
                <Route path="new(/:pipeID)" component={renderIfSubConnected(SubNewPipe)} />
                <Route
                  path="permissions"
                  component={renderIfSubConnected(SubPipeListPermissions)}
                  onEnter={(route, replace, callback) =>
                    setLastViewedTab('pipes', 'permissions', callback)
                  }
                />
                <Route
                  path="prototype-permissions"
                  component={renderIfSubConnected(SubPipePrototypePermissions)}
                  onEnter={(route, replace, callback) =>
                    setLastViewedTab('pipes', 'prototype-permissions', callback)
                  }
                />
                <Route path="pipe/:pipeID" component={renderIfSubConnected(SubPipe)}>
                  <IndexRoute
                    onEnter={(route, replace, callback) =>
                      redirectFromIndexRoute(
                        'pipe',
                        'dashboard',
                        `/subscription/${route.params.subId}/pipes/pipe/${route.params.pipeID}`,
                        replace,
                        callback
                      )
                    }
                  />
                  <Route
                    path="dashboard"
                    component={SubPipeDashboard}
                    onEnter={(route, replace, callback) =>
                      setLastViewedTab('pipe', 'dashboard', callback)
                    }
                  />
                  <Route
                    path="edit"
                    component={SubEditPipe}
                    onEnter={(route, replace, callback) =>
                      setLastViewedTab('pipe', 'edit', callback)
                    }
                  />
                  <Route
                    path="input"
                    component={SubPipeInput}
                    onEnter={(route, replace, callback) =>
                      setLastViewedTab('pipe', 'input', callback)
                    }
                  />
                  <Route
                    path="output"
                    component={SubPipeOutput}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('pipe', 'output', callback);
                    }}
                  />
                  <Route
                    path="inferred-schema"
                    component={PipeInferredSchema}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('pipe', 'inferred-schema', callback);
                    }}
                  />
                  <Route
                    path="dead-letters"
                    component={PipeDeadLetters}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('pipe', 'dead-letters', callback);
                    }}
                  />
                  <Route
                    path="graph"
                    component={PipeGraph}
                    onEnter={(route, replace, callback) =>
                      setLastViewedTab('pipe', 'graph', callback)
                    }
                  />
                  <Redirect path="flow" to="graph" />
                  <Route
                    path="permissions"
                    component={SubPipePermissions}
                    onEnter={(route, replace, callback) =>
                      setLastViewedTab('pipe', 'permissions', callback)
                    }
                  />
                  <Route
                    path="insights"
                    component={SubPipeInsights}
                    onEnter={(route, replace, callback) =>
                      setLastViewedTab('pipe', 'insights', callback)
                    }
                  />
                  <Route
                    path="notifications"
                    component={SubPipeNotifications}
                    onEnter={(route, replace, callback) =>
                      setLastViewedTab('pipe', 'notifications', callback)
                    }
                  />
                  <Route
                    path="overview"
                    component={SubPipeGlobalOverview}
                    onEnter={(route, replace, callback) =>
                      setLastViewedTab('pipe', 'overview', callback)
                    }
                  />
                  <Route path="debug" component={SubPipeDebug} />
                  <Route
                    path="execution"
                    component={SubPipeExecutionLog}
                    onEnter={(route, replace, callback) =>
                      setLastViewedTab('pipe', 'execution', callback)
                    }
                  />
                  <Route
                    path="indexes"
                    component={SubPipeIndexes}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('pipe', 'indexes', callback);
                    }}
                  />
                </Route>
              </Route>
              <Route path="systems">
                <IndexRoute
                  onEnter={(route, replace, callback) =>
                    redirectFromIndexRoute(
                      'systems',
                      'all',
                      `/subscription/${route.params.subId}/systems`,
                      replace,
                      callback
                    )
                  }
                />
                <Route
                  path="all"
                  component={renderIfSubConnected(SubSystemList)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('systems', 'all', callback);
                  }}
                />
                <Route path="new(/:systemID)" component={renderIfSubConnected(SubNewSystem)} />
                <Route
                  path="prototype-permissions"
                  component={renderIfSubConnected(SubSystemPrototypePermissions)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('systems', 'prototype-permissions', callback);
                  }}
                />
                <Route
                  path="permissions"
                  component={renderIfSubConnected(SubSystemListPermissions)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('systems', 'permissions', callback);
                  }}
                />
                <Route path="system/:systemID" component={renderIfSubConnected(SubSystem)}>
                  <IndexRoute
                    onEnter={(route, replace, callback) =>
                      redirectFromIndexRoute(
                        'system',
                        'dashboard',
                        `/subscription/${route.params.subId}/systems/system/${route.params.systemID}`,
                        replace,
                        callback
                      )
                    }
                  />
                  <Route
                    path="dashboard"
                    component={SubSystemDashboard}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('system', 'dashboard', callback);
                    }}
                  />
                  <Route
                    path="status"
                    component={SystemStatusPage}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('system', 'status', callback);
                    }}
                  />
                  <Route
                    path="secrets"
                    component={SystemSecretsPage}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('system', 'secrets', callback);
                    }}
                  />
                  <Route
                    path="edit"
                    component={EditSystem}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('system', 'edit', callback);
                    }}
                  />
                  <Route
                    path="graph"
                    component={SystemGraph}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('system', 'graph', callback);
                    }}
                  />
                  <Redirect path="flow" to="graph" />
                  <Route
                    path="overview"
                    component={SystemOverview}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('system', 'overview', callback);
                    }}
                  />
                  <Route
                    path="permissions"
                    component={SubSystemPermissions}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('system', 'permissions', callback);
                    }}
                  />
                </Route>
              </Route>
              <Redirect from="search" to="browse" />
              <Redirect from="data-catalog" to="browse" />
              <Route path="browse">
                <IndexRoute
                  onEnter={(route, replace, callback) =>
                    redirectFromIndexRoute(
                      'browse',
                      'search',
                      `/subscription/${route.params.subId}/browse`,
                      replace,
                      callback
                    )
                  }
                />
                <Route
                  path="search"
                  component={renderIfSubConnected(SearchPage)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('browse', 'search', callback);
                  }}
                />
                <Route path="ni/:ni" component={renderIfSubConnected(NIInspectorPage)} />
                <Route
                  path="entity-types"
                  component={renderIfSubConnected(EntityTypesListPage)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('browse', 'entity-types', callback);
                  }}
                />
                <Route
                  path="models"
                  component={renderIfSubConnected(ModelsListPage)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('browse', 'models', callback);
                  }}
                />
                <Route path="models/:modelId" component={renderIfSubConnected(ModelPage)}>
                  <IndexRoute
                    onEnter={(route, replace, callback) =>
                      redirectFromIndexRoute(
                        'model',
                        'overview',
                        `/subscription/${route.params.subId}/browse/models/${route.params.modelId}`,
                        replace,
                        callback
                      )
                    }
                  />
                  <Route
                    path="overview"
                    component={ModelOverview}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('model', 'overview', callback);
                    }}
                  />
                </Route>
                <Route
                  path="entity-types/:modelId/:entityTypeId"
                  component={renderIfSubConnected(EntityTypePage)}
                >
                  <IndexRoute
                    onEnter={(route, replace, callback) =>
                      redirectFromIndexRoute(
                        'entity-type',
                        'overview',
                        `/subscription/${route.params.subId}/browse/entity-types/${route.params.modelId}/${route.params.entityTypeId}`,
                        replace,
                        callback
                      )
                    }
                  />
                  <Route
                    path="overview"
                    component={EntityTypeOverview}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('data-type', 'overview', callback);
                    }}
                  />
                </Route>
                <Route
                  path="properties/:propertyId"
                  component={renderIfSubConnected(EntityTypePropertyPage)}
                >
                  <IndexRoute
                    onEnter={(route, replace, callback) =>
                      redirectFromIndexRoute(
                        'entity-type-property',
                        'overview',
                        `/subscription/${route.params.subId}/browse/properties/${route.params.propertyId}`,
                        replace,
                        callback
                      )
                    }
                  />
                  <Route
                    path="overview"
                    component={EntityTypePropertyOverview}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('data-type', 'overview', callback);
                    }}
                  />
                </Route>
              </Route>
              <Route path="flows">
                <IndexRoute
                  onEnter={(route, replace, callback) =>
                    redirectFromIndexRoute(
                      'flows',
                      'all',
                      `/subscription/${route.params.subId}/flows`,
                      replace,
                      callback
                    )
                  }
                />
                <Route
                  path="all"
                  component={renderIfSubConnected(FlowsList)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('flows', 'all', callback);
                  }}
                />

                <Route path="flow" component={FlowPage}>
                  <IndexRedirect to="dashboard" />
                  <Route path="dashboard" component={FlowDashboardPage} />
                  <Route path="config" component={FlowConfigPage} />
                  <Route path="input" component={FlowInputPage} />
                  <Route path="output" component={FlowOutputPage} />
                </Route>
              </Route>
              <Route path="gdpr" component={renderIfSubConnected(withGdprRedirect(Gdpr))}>
                <IndexRoute
                  onEnter={(route, replace, callback) =>
                    redirectFromIndexRoute(
                      'gdpr',
                      'data-types',
                      `/subscription/${route.params.subId}/gdpr`,
                      replace,
                      callback
                    )
                  }
                />
                <Route
                  path="data-types"
                  component={GdprDataTypes}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('gdpr', 'data-types', callback);
                  }}
                />
                <Route
                  path="access-requests"
                  component={GdprAccessRequests}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('gdpr', 'access-requests', callback);
                  }}
                />
                <Route
                  path="change-requests"
                  component={GdprChangeRequests}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('gdpr', 'change-requests', callback);
                  }}
                />
                <Route
                  path="translations"
                  component={GdprTranslations}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('gdpr', 'translations', callback);
                  }}
                />
                <Route
                  path="purposes"
                  component={GdprPurposePage}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('gdpr', 'purposes', callback);
                  }}
                />
                <Route
                  path="datatypes"
                  component={GdprDatatypePage}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('gdpr', 'datatypes', callback);
                  }}
                />
                <Route
                  path="policies"
                  component={GdprPolicyPage}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('gdpr', 'policies', callback);
                  }}
                />
              </Route>
              <Route
                path="settings-subscription"
                component={renderIfSubMember(SubscriptionSettings)}
              >
                <IndexRoute
                  onEnter={(route, replace, callback) =>
                    redirectFromIndexRoute(
                      'settings-subscription',
                      'basics',
                      `/subscription/${route.params.subId}/settings-subscription`,
                      replace,
                      callback
                    )
                  }
                />
                <Route
                  path="basics"
                  component={SubSettingsBasics}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-subscription', 'basics', callback);
                  }}
                />
                <Route
                  path="license"
                  component={SubSettingsLicense}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-subscription', 'license', callback);
                  }}
                />
                <Route
                  path="network"
                  component={SettingsSubscriptionNetwork}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-subscription', 'network', callback);
                  }}
                />
                <Route
                  path="products"
                  component={SubSettingsProducts}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-subscription', 'products', callback);
                  }}
                />
                <Route
                  path="vpn"
                  component={SubSettingsVpn}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-subscription', 'vpn', callback);
                  }}
                />
                <Route
                  path="members"
                  component={SubSettingsMembers}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-subscription', 'members', callback);
                  }}
                />
                <Route
                  path="roles"
                  component={SubSettingsRoles}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-subscription', 'roles', callback);
                  }}
                />
                <Route
                  path="jwt"
                  component={SubSettingsJwt}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-subscription', 'jwt', callback);
                  }}
                />
                <Route
                  path="notifications"
                  component={SubSettingsNotifications}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-subscription', 'notifications', callback);
                  }}
                />
              </Route>
              <Route path="settings-datahub">
                <IndexRoute
                  onEnter={(route, replace, callback) =>
                    redirectFromIndexRoute(
                      'settings-datahub',
                      'queues',
                      `/subscription/${route.params.subId}/settings-datahub`,
                      replace,
                      callback
                    )
                  }
                />
                <Route
                  path="queues"
                  component={renderIfSubConnected(DhSettingsQueues)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-datahub', 'queues', callback);
                  }}
                />
                <Route
                  path="internal-datasets"
                  component={renderIfSubConnected(DhSettingsInternalDatasets)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-datahub', 'internal-datasets', callback);
                  }}
                />
                <Route path="dataset/:datasetID" component={renderIfSubConnected(SubDataset)}>
                  <IndexRoute
                    onEnter={(route, replace, callback) =>
                      redirectFromIndexRoute(
                        'dataset',
                        'view',
                        `/subscription/${route.params.subId}/settings-datahub/dataset/${route.params.datasetID}`,
                        replace,
                        callback
                      )
                    }
                  />
                  <Redirect from="overview" to="view" />
                  <Route
                    path="view"
                    component={DatasetInspectorContainer}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('dataset', 'view', callback);
                    }}
                  />
                  <Route
                    path="permissions"
                    component={SubDatasetPermissions}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('dataset', 'permissions', callback);
                    }}
                  />
                  <Route
                    path="indexes"
                    component={SubDatasetIndexes}
                    onEnter={(route, replace, callback) => {
                      setLastViewedTab('dataset', 'indexes', callback);
                    }}
                  />
                </Route>
                <Route
                  path="logs"
                  component={renderIfSubConnected(DhSettingsLogs)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-datahub', 'logs', callback);
                  }}
                />
                <Route
                  path="tools"
                  component={renderIfSubConnected(DhSettingsTools)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-datahub', 'tools', callback);
                  }}
                />
                <Route
                  path="variables"
                  component={renderIfSubConnected(DhSettingsVariables)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-datahub', 'variables', callback);
                  }}
                />
                <Route
                  path="metadata"
                  component={renderIfSubConnected(DhSettingsMetadata)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-datahub', 'metadata', callback);
                  }}
                />
                <Route
                  path="permissions"
                  component={renderIfSubConnected(DhSettingsPermissions)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-datahub', 'permissions', callback);
                  }}
                />
                <Route
                  path="stacktrace"
                  component={renderIfSubConnected(DatahubStacktracePage)}
                  onEnter={(route, replace, callback) =>
                    requireGroupMembership(
                      'group:Admin',
                      `/subscription/${route.params.subId}/settings-datahub/`,
                      replace,
                      callback
                    )
                  }
                />
              </Route>

              <Route
                path="settings-databrowser/:databrowserId"
                component={renderIfDatabrowserConnected(DatabrowserSettings)}
                onEnter={requireAuth}
              >
                <IndexRoute
                  onEnter={(route, replace, callback) =>
                    redirectFromIndexRoute(
                      'settings-databrowser',
                      'databrowser',
                      `/subscription/${route.params.subId}/settings-databrowser/${route.params.databrowserId}`,
                      replace,
                      callback
                    )
                  }
                />
                <Route
                  path="configfile"
                  component={renderIfDatabrowserConnected(DatabrowserSettingsConfigFile)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-databrowser', 'configfile', callback);
                  }}
                />
                <Route
                  path="databrowser"
                  component={renderIfDatabrowserConnected(DatabrowserSettingsDatabrowser)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-databrowser', 'databrowser', callback);
                  }}
                />
                <Route
                  path="network"
                  component={renderIfDatabrowserConnected(DatabrowserSettingsNetwork)}
                  onEnter={(route, replace, callback) => {
                    setLastViewedTab('settings-databrowser', 'network', callback);
                  }}
                />
              </Route>
            </Route>
            <Route path="server-error" component={ServerError} />
            <Route path="*" component={NotFound} />
          </Route>
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  );
};

export default App;
