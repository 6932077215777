'use strict';

exports.__esModule = true;
exports.array = exports.object = exports.boolean = exports.date = exports.number = exports.string = exports.mixed = undefined;

var _printValue = require('./util/printValue');

var _printValue2 = _interopRequireDefault(_printValue);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var mixed = exports.mixed = {
  default: '${path} is invalid',
  required: '${path} is a required field',
  oneOf: '${path} must be one of the following values: ${values}',
  notOneOf: '${path} must not be one of the following values: ${values}',
  notType: function notType(_ref) {
    var path = _ref.path,
        type = _ref.type,
        value = _ref.value,
        originalValue = _ref.originalValue;

    var isCast = originalValue != null && originalValue !== value;
    var msg = path + ' must be a `' + type + '` type, ' + ('but the final value was: `' + (0, _printValue2.default)(value, true) + '`') + (isCast ? ' (cast from the value `' + (0, _printValue2.default)(originalValue, true) + '`).' : '.');

    if (value === null) {
      msg += '\n If "null" is intended as an empty value be sure to mark the schema as `.nullable()`';
    }

    return msg;
  }
};

var string = exports.string = {
  length: '${path} must be exactly ${length} characters',
  min: '${path} must be at least ${min} characters',
  max: '${path} must be at most ${max} characters',
  matches: '${path} must match the following: "${regex}"',
  email: '${path} must be a valid email',
  url: '${path} must be a valid URL',
  trim: '${path} must be a trimmed string',
  lowercase: '${path} must be a lowercase string',
  uppercase: '${path} must be a upper case string'
};

var number = exports.number = {
  min: '${path} must be greater than or equal to ${min}',
  max: '${path} must be less than or equal to ${max}',
  less: '${path} must be less than ${less}',
  more: '${path} must be greater than ${more}',
  notEqual: '${path} must be not equal to ${notEqual}',
  positive: '${path} must be a positive number',
  negative: '${path} must be a negative number',
  integer: '${path} must be an integer'
};

var date = exports.date = {
  min: '${path} field must be later than ${min}',
  max: '${path} field must be at earlier than ${max}'
};

var boolean = exports.boolean = {};

var object = exports.object = {
  noUnknown: '${path} field cannot have keys not specified in the object shape'
};

var array = exports.array = {
  min: '${path} field must have at least ${min} items',
  max: '${path} field must have less than or equal to ${max} items'
};

exports.default = {
  mixed: mixed,
  string: string,
  number: number,
  date: date,
  object: object,
  array: array,
  boolean: boolean
};