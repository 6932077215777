export const TestID = {
  AvatarCustomConnectionURL: 'avatar-custom-connection-url',
  AvatarDefaultConnectionURL: 'avatar-default-connection-url',
  BrowserListEntity: 'browser-list-entity',
  ChangelogBadgeIndicator: 'changelog-badge-indicator',
  ChangelogButton: 'changelog-button',
  ChangelogDialog: 'changelog-dialog',
  ChangelogDialogAutoOpenCheckbox: 'changelog-dialog-auto-open-checkbox',
  ChangelogDialogCloseButton: 'changelog-dialog-close-button',
  ChangelogEntry: 'changelog-entry',
  ConnectButton: 'connect-button',
  ConnectingNetworkLinkButton: 'connecting-network-link-button',
  ConnectionFailedMessage: 'connection-failed-message',
  ConnectionFailedMessageDetailsLinkButton: 'connection-failed-message-details-link-button',
  ConnectionFailedModal: 'connection-failed-modal',
  ConnectNetworkLinkButton: 'connect-network-link-button',
  CustomConnectionStringInput: 'custom-connection-string-input',
  DashboardSubscriptionCard: 'dashboard-subscription-card',
  DisconnectNetworkLinkButton: 'disconnect-network-link-button',
  DatasetInspectorFilter: 'dataset-inspector-filter',
  DatasetInspectorFilterAll: 'dataset-inspector-filter-all',
  DatasetInspectorFilterDeleted: 'dataset-inspector-filter-deleted',
  DatasetInspectorFilterLatest: 'dataset-inspector-filter-latest',
  LoginButton: 'login-button',
  LoginEmailAndPasswordRequired: 'login-email-and-password-required',
  LoginErrorMessageCaptchaRequired: 'login-error-message-captcha-required',
  LoginForm: 'login-form',
  LoginPasswordInput: 'login-password-input',
  LoginRecaptcha: 'login-recaptcha',
  LoginUserNameInput: 'login-user-name-input',
  LoginWrongCredential: 'login-wrong-credential',
  NetworkPolicyLink: 'network-policy-link',
  PersistConnectionStringCheckbox: 'persist-connection-string-checkbox',
  RestoreDefaultConnectionLinkButton: 'restore-default-connection-link-button',
  SesamHubPieChartLink: 'sesam-hub-pie-chart-link',
  SesamHubPieChartSystem: 'sesam-hub-pie-chart-system',
  AppBarMenuItem: 'app-bar-menu-item',
  AppBarMenuLink: 'app-bar-menu-link',
  RepostAllDeadLettersEntitiesButton: 'repost-all-dead-letters-entities-button',
  RepostSelectedEntityButton: 'repost-selected-entity-button',
  RepostSelectedDeadEntityButton: 'repost-selected-dead-entity-button',
  postEntitiesModalTitle: 'post-entities-modal-title',
  postEntitiesModalSubTitle: 'post-entities-modal-sub-title',
  PostEntityModalPostButton: 'post-entity-modal-post-button',
  JsonEditorContainerDiv: 'json-editor-container-div',
  DatasetInspectorBrowserListEntityTitle: 'dataset-inspector-browser-list-entity-title',
  DatasetInspectorViewerEntityHeader: 'dataset-inspector-viewer-entity-header',
  ErrorMessageContainerOnDeadLetterPostModal: 'error-message-container-on-dead-letter-post-modal',
  SubscriptionCardHeader: 'subscription-card-header',
  SubscriptionCardText: 'subscription-card-text',
  SubscriptionCardActions: 'subscription-card-actions',
  CreateNewPaymentMethodLink: 'create-new-payment-method-link',
  PaymentMethodTypeRadioGroup: 'payment-method-type-radio-group',
  PaymentMethodInputName: 'payment-method-input-name',
  PaymentMethodInputDescription: 'payment-method-input-description',
  PaymentMethodInputCompanyNumber: 'payment-method-input-company-number',
  PaymentMethodInputNameOfResponsible: 'payment-method-input-name-of-responsible',
  PaymentMethodInputPhoneNumber: 'payment-method-input-phone-number',
  PaymentMethodInputAddress1: 'payment-method-input-address-1',
  PaymentMethodFormAddButton: 'payment-method-form-add-button',
  JsonWebTokenTabHeader: 'json-web-token-tab-header',
  JsonWebTokenTabDetails: 'json-web-token-tab-details',
  JsonWebTokenTabExistingTokensHeader: 'json-web-token-tab-existing-tokens-header',
  ExpansionPanelTitle: 'expansion-panel-title',
  AddJwtFormName: 'add-jwt-form-name',
  AddJwtFormDescription: 'add-jwt-form-description',
  AddJwtFormOwner: 'add-jwt-form-owner',
  AddJwtFormRole: 'add-jwt-form-role',
  AddJwtFormAddButton: 'add-jwt-form-add-button',
  NewJwtModalHeader: 'new-jwt-modal-header',
  NewJwtModalDescription: 'new-jwt-modal-description',
  NewJwtModalDismissButton: 'new-jwt-modal-dismiss-button',
  KeyValuePairKey: 'key-value-pair-key',
  KeyValuePairValue: 'key-value-pair-value',
  SignUpLink: 'sign-up-link',
  SignUpButton: 'sign-up-button',
  SignUpAgreementCheckbox: 'sign-up-agreement-checkbox',
  SignUpFormNameInput: 'sign-up-form-name-input',
  SignUpFormEmailInput: 'sign-up-form-email-input',
  SignUpFormPasswordInput: 'sign-up-form-password-input',
  SignUpFormConfirmPasswordInput: 'sign-up-form-confirm-password-input',
  JsonWebTokenTabHeader: 'json-web-token-tab-header',
  JsonWebTokenTabDetails: 'json-web-token-tab-details',
  JsonWebTokenTabExistingTokensHeader: 'json-web-token-tab-existing-tokens-header',
  ExpansionPanelTitle: 'expansion-panel-title',
  AddJwtFormName: 'add-jwt-form-name',
  AddJwtFormDescription: 'add-jwt-form-description',
  AddJwtFormOwner: 'add-jwt-form-owner',
  AddJwtFormRole: 'add-jwt-form-role',
  AddJwtFormAddButton: 'add-jwt-form-add-button',
  NewJwtModalHeader: 'new-jwt-modal-header',
  NewJwtModalDescription: 'new-jwt-modal-description',
  NewJwtModalDismissButton: 'new-jwt-modal-dismiss-button',
  KeyValuePairKey: 'key-value-pair-key',
  KeyValuePairValue: 'key-value-pair-value',
} as const;
