import React from 'react';
import ExternalLink from 'Common/Links/ExternalLink';
import SesamLink from 'Common/Links/SesamLink';
import CenteredLayout from '../layout/centered';

import './server-error.css';
import { Links } from 'Constants/links';

function ServerError() {
  return (
    <CenteredLayout>
      <main className="server-error-page">
        <h2 className="heading-page">Server error</h2>

        <p>
          Uh-oh. Something unexpected just happened. Please <SesamLink to="/">try again</SesamLink>{' '}
          in about 30 seconds. If the issue persists, try using our{' '}
          <ExternalLink href={Links.FallbackPortalUrl}>fallback portal</ExternalLink>, available as a temporary read-only option.{' '}
          If you continue to experience problems, contact us at{' '}
          <ExternalLink href={Links.MailToInfoAtSesamDotIO}>info@sesam.io</ExternalLink>.
        </p>
      </main>
    </CenteredLayout>
  );
}

export default ServerError;
